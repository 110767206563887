import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';

import { SelectPanel } from '@/components';
import actionItem from '../Partials/ActionItem/ActionItem.vue';
import buildingKitAiTesting from '../Partials/BuildingKitAiTesting/BuildingKitAiTesting.vue';
import middlePaneHeader from '../Partials/MiddlePaneHeader/MiddlePaneHeader.vue';
import ActionContainer from '../Partials/ActionContainer/ActionContainer.vue';
import {
  iqtoolsErrorHandler,
  removeActionIfExist,
  removeDuplicateNameAction,
  makeDependencyAlertMsg,
} from '@/libs';
import { STATUS_CODES } from '@/constants';
import * as log from 'loglevel';

const LIMIT = 30;
@Component({
  components: {
    actionItem,
    buildingKitAiTesting,
    SelectPanel,
    middlePaneHeader,
    ActionContainer,
  },
  computed: {
    ...mapGetters({
      preconfiguredEntitiesFunc: 'entities/preconfiguredEntities',
    }),
    ...mapState({
      workflows: state => state.workflows.workflows,
      loaded: state => state.workflows.workflowsLoaded,
      useConditionalAction:
        state => state.featureFlags.FEATURE_FLAGS.SUPPORT_CONDITIONAL_ACTION_IN_WORKFLOW,
    }),
    isSelectedItemModified() {
      if (!this.selected) {
        return true;
      }

      if (JSON.stringify(this.selected)
          !== JSON.stringify(this.workflows[this.selectedIndex])) {
        return true;
      }

      return false;
    },
    middleColumnsClassObj() {
      return {
        pristine: !this.isSelectedItemModified,
      };
    },
    preconfiguredEntities() {
      return (this.preconfiguredEntitiesFunc() || []).map(e => e.name);
    },
  },
})

class Workflows extends Vue {
  // left panel state
  limit = LIMIT;
  offset = 0;
  search = '';

  // temp workflow storage
  selectedIndex = -1;
  selected = null;

  newWorkflow = '';
  identifier = 0;

  @Watch('$route')
  onRouteChanged(to) {
    this.resetPage();
    const workflowName = get(to, 'hash', '').replace('#', '');
    if (workflowName) {
      return this.searchWorkflows(workflowName);
    }
    return this.loadWorkflows().then(() => {
      const id = parseInt(this.$route.params.id, 10);
      const itemIndex = !id ? 0 : this.workflows.findIndex(item => item.id === id);
      this.selectWorkflow(itemIndex);
    });
  }

  resetPage() {
    this.limit = LIMIT;
    this.offset = 0;
    this.selectedIndex = -1;
  }

  beforeUpdate() {
    if (this.selectedIndex === -1 && this.workflows.length > 0) {
      this.selectWorkflow(0);
    }
  }

  createWorkflow(name) {
    this.$store.dispatch('workflows/createWorkflow', { name })
      .then(({ data }) => {
        this.$aiq.notify.success(
          `${name} has been added to workflows.`,
        );
        this.newWorkflow = '';
        this.selectWorkflowById(data.id);
      }).catch(err => {
        iqtoolsErrorHandler(this, err, 'workflow');
      });
  }

  deleteWorkflow(id) {
    const { name } = this.workflows.find(e => e.id === id);
    this.$aiq.confirm(
      'Delete Workflow',
      `Are you sure you want to delete <b>${name}</b>?`,
    ).then(
      () => {
        this.$store.dispatch('workflows/deleteWorkflow', id)
          .then(() => {
            this.$aiq.notify.success(`${name} has been deleted from workflows.`);
            if (id === this.selected.id) {
              this.selectWorkflow(this.selectedIndex);
            }
          })
          .catch(res => {
            const { title, content } = makeDependencyAlertMsg('Workflow', res, {translation: this.$t});
            this.$aiq.confirm(title, content);
          });
      },
      () => {},
    );
  }

  loadWorkflows(searchTerms) {
    return this.$store.dispatch('workflows/getWorkflowsList', [
      { q: searchTerms, offset: this.offset, limit: LIMIT },
      !!(this.offset === 0),
    ]).then(data => {
      this.$store.dispatch('workflows/markWorkflowsAsLoaded', data.data.pagination.rowCount < (this.offset + this.limit));
      this.offset += this.limit;

      return data;
    });
  }

  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch('entities/getEntitiesList'),
        this.$store.dispatch('dialogs/getEntityOperators'),
        this.onRouteChanged(this.$route),
      ]);
    } catch (err) {
      this.$aiq.notify.error('Unable to load workflow data');
      log.error(err);
    }
  }

  saveWorkflowName(newName) {
    if (this.selected.name === newName) {
      return;
    }

    this.selected.name = newName;

    this._updateWorkflow()
      .then(data => this.selectWorkflow(this.selectedIndex)
        .then(() => {
          this.$aiq.notify.success(`${get(data, 'data.name', newName)} has been saved.`);
          return data;
        })).catch(err => iqtoolsErrorHandler(this, err, 'workflow'));
  }

  /**
   * Event hook when copy icon is clicked.
   */
  onCopyWorkflow() {
    this.$store.dispatch('workflows/copyWorkflow', this.selected)
      .then(created => {
        this.$aiq.notify.success(
          `${created.name} has been added to workflows.`,
        );

        const index = this.workflows.findIndex(workflow => workflow.name === created.name);

        this.selectWorkflow(index);
      }, err => {
        iqtoolsErrorHandler(this, err, 'workflow');
      });
  }

  searchWorkflows(searchTerms) {
    this.search = searchTerms;
    this.identifier ++;
    this.$store.dispatch('workflows/markAsWorkflowsLoaded', false);
    this.offset = 0;

    this.loadWorkflows(searchTerms);
  }

  // TODO (Gabe) - Remove and use selectWorkflowById
  selectWorkflow(index) {
    if (!this.workflows || this.workflows.length <= 0) {
      this.selectedIndex = -1;
      this.selected = null;
      return;
    }

    if (index < 0) {
      this.selectedIndex = 0;
    } else if (index >= this.workflows.length) {
      this.selectedIndex = this.workflows.length - 1;
    } else {
      this.selectedIndex = index;
    }
    return this.$store.dispatch('workflows/selectWorkflow', this.workflows[this.selectedIndex])
      .then(() => {
        this.selected = cloneDeep(this.workflows[this.selectedIndex]);
      },
      () => this.$aiq.notify.error('Workflow not found'));
  }

  selectWorkflowById(id) {
    const index = this.workflows.findIndex(workflow => workflow.id === id);
    this.selectWorkflow(index);
  }

  onDeleteSuccessAction(idx) {
    const actions = this.selected.payload.success_actions;
    removeActionIfExist(actions, idx);
  }

  onDeleteFailureAction(idx) {
    const actions = this.selected.payload.failure_actions;
    removeActionIfExist(actions, idx);
  }

  onAddActions(event, actions) {
    removeDuplicateNameAction(
      actions,
      event.newIndex,
      this.selected,
    );
  }

  onAddFailureActions(event) {
    this.onAddActions(event, this.selected.payload.failure_actions);
  }

  onAddSuccessActions(event) {
    this.onAddActions(event, this.selected.payload.success_actions);
  }

  // TODO (Gabe) - create mixin for search and scroll load
  onScrollLoad($state, searchTerms) {
    // this.loaded refers to all data loaded
    if (!this.loaded) {
      this.loadWorkflows(searchTerms)
        .then(data => {
          if (this.selectedIndex === -1 && this.workflows.length > 0) {
            this.selectWorkflow(0);
          }
          const dataItems = get(data, 'data.data', []);
          if (!dataItems[0] || this.loaded) {
            $state.complete();
          } else {
            $state.loaded();
          }
        });
    } else {
      $state.complete();
    }
  }

  onEditCancel() {
    this.selectWorkflow(this.selectedIndex);
  }

  onEditSave() {
    this._updateWorkflow()
      .then(updatedWorkflow => {
        this.selected = cloneDeep(updatedWorkflow);
        this.$aiq.notify.success(`${this.selected.name} has been saved.`);
      })
      .catch(res => {
        if (res.response.status === STATUS_CODES.CONFLICT) {
          const messagePrefix = 'To finish this workflow, customer\'s ';
          let messageSuffix = 'needed. Do you want to proceed it anyway?';
          let message = '';

          // wrap with style for entities
          let missingEntities = get(res.response, 'data.missing', []);
          missingEntities = missingEntities.map(entity => `<span style="color:#5993FF">${entity}</span>`);

          // build message
          if (missingEntities.length === 1) {
            messageSuffix = ` is ${messageSuffix}`;
            message = messagePrefix + missingEntities + messageSuffix;
          } else {
            messageSuffix = ` are ${messageSuffix}`;
            const commaSeparatedEntities = missingEntities.slice(0, missingEntities.length - 1);

            message = `${messagePrefix + commaSeparatedEntities.join(', ')
            } and ${missingEntities[missingEntities.length - 1]
            }${messageSuffix}`;
          }

          this.$aiq.confirm('Missing entities', message)
            .then(() => this._updateWorkflow(true).then(() => {
              this.$aiq.notify.success(`${this.selected.name} has been saved.`);
            })).catch(() => this.$aiq.notify.error('Unable to save'));
        }
      });
  }

  _updateWorkflow(force = false) {
    return this.$store.dispatch('workflows/updateWorkflow', { payload: this.selected, force });
  }

  onActionItemClick(item) {
    this.$store.dispatch('routing/routeIqtoolActionItem', item);
  }
}
export default toNative(Workflows);
