export const makeDependencyAlertMsg = (parentName, res, ctx) => {
  const data = res.data;
  const suffix = ctx.translation('iq_tools_tab.dependency_error');
  const list = [
    `${data.contents.intents.length} intents: ${getDependencyName(data, 'intents')}`,
    `${data.contents.dialogs.length} dialogs: ${getDependencyName(data, 'dialogs')}`,
    `${data.contents.workflows.length} workflows: ${getDependencyName(data, 'workflows')}`,
  ];

  const contents = list.map(item => `<li>${item}</li>`).join('');

  return {
    title: `Delete ${parentName}`,
    content: `${'<div>'
      + '<div>This '}${parentName.toLowerCase()} ${ctx.translation('iq_tools_tab.nested_dependency_msg')} </div> `
      + `<ul class='dependency-items-list'>${contents}</ul>`
      + `<div>${suffix}</div>`
      + '</div>',
  };
};

const getDependencyName = (data, key)=>{
  return data.contents[key].map(item=> `<b>${item.name}</b>`).join(', ');
};

export const changeEntityTypeWarning = {
  title: 'Change Entity Type?',
  content: 'Changing the entity type can significantly change the behavior of the system. Are you sure you want to proceed?',
};
