<template lang="pug">
  .customer-info
    basic-customer-info(:customer-record="customerRecord"
                        :customer-record-link="customerRecordLink"
                        :profile="profile"
                        :primaryAgent="primaryAgent"
                        :profileMapping="profileMapping"
                        :entities="entities"
                        :customerSegment="customerSegment"
                        :customer="customer"
                        :disable="disable"
                        :includeDetails="includeDetails"
                        @updateField="updateField")
    template(v-if="showDeviceInfo")
      br
      device-info(:messages="messages")
      br
    template(v-if="showFeedbackInfo")
      .bottom-line
      br
      feedback-info(:info="lastFeedbackInfo")
</template>

<script>
import get from 'lodash/get';
import assign from 'lodash/assign';
import BasicCustomerInfo from './BasicCustomerInfo.vue';
import DeviceInfo from './DeviceInfo.vue';
import FeedbackInfo from './FeedbackInfo.vue';
import { formatCustomerSegmentsForDisplay } from '@/libs/customerSegments';
import { PROFILE_EDITOR_TYPE } from '@/constants/settings';

function resolveFieldName(item) {
  // Note: it is returning 'name' for CONCAT_STRING as this is an exceptional field that are
  // combined with first_name and the last name. We might need a change in future for this.
  return (item.type === PROFILE_EDITOR_TYPE.CONCAT_STRING && !item.field) ? 'name' : item.field;
}

export default {
  components: {
    BasicCustomerInfo,
    DeviceInfo,
    FeedbackInfo,
  },
  data() {
    return {
      // A container that needs to store values temporarily.
      cachedProfile: {},
    };
  },
  computed: {
    profile() {
      return assign({}, this.customer.profile, this.cachedProfile);
    },

    customerRecord() {
      return this.profile.external_id || '';
    },
    customerRecordLink() {
      const externalLink = get(this.tenant, 'external_customer_link');
      if (externalLink) {
        // The externalLink may contains link with template which has
        // '{{}}' as a replacing location indicator.
        // ex) https://test.com/camp/default.aspx?CustomerId={{}} will be replaced to
        // https://test.com/camp/default.aspx?CustomerId=1234 if customerRecord is 1234
        return externalLink.replace('{{}}', this.customerRecord);
      }
      return '';
    },
    primaryAgent() {
      const firstName = get(this, 'conversation.primaryAgent.profile.first_name', '');
      const lastName = get(this, 'conversation.primaryAgent.profile.last_name', '');
      return `${firstName} ${lastName}`.trim();
    },
    entities() {
      const entities = this.conversation.ai_state ? this.conversation.ai_state.entities : null;
      return Array.of.apply(null, entities);
    },
    editables() {
      return get(this.sidePanelConfig, 'profile_mapping', [])
        .filter(mapping => mapping.editable)
        .map(resolveFieldName);
    },
    profileMapping() {
      return get(this.sidePanelConfig, 'profile_mapping', []);
    },
    customerSegment() {
      return formatCustomerSegmentsForDisplay(get(this.profile, 'customer_segment', ''));
    },
  },
  props: {
    channel: {
      type: String,
    },
    conversation: {
      type: Object,
    },
    customer: {
      type: Object,
    },
    tenant: {
      type: Object,
    },
    sidePanelConfig: {
      type: Object,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    includeDetails: {
      type: Boolean,
      default: true,
    },
    messages: {
      type: Array,
      default: [],
    },
    lastFeedbackInfo: {
      type: Object,
      default: null,
    },
    showDeviceInfo:{
      type: Boolean,
      default: true,
    },
    showFeedbackInfo:{
      type: Boolean,
      default: true,
    }
  },
  emits: ['updateProfile'],
  watch: {
    customer() {
      // invalid cache whenever new customer config is set.
      this.cachedProfile = {};
    },
  },
  methods: {
    updateField({ mapping, value }) {
      // If editable field is set to be false, the update should be always local.
      // So we store it only in cachedProfile which can disappear if this object
      // gets updated.
      if (!mapping.editable && mapping.field) {
        this.cachedProfile[mapping.field] = value;
        return;
      }

      if (mapping.type === PROFILE_EDITOR_TYPE.CONCAT_STRING) {
        const changedProfile = this.concatStringToProfile(mapping.fields, value);
        this.updateProfile({ ...this.profile, ...changedProfile });
      } else {
        this.updateProfile({ ...this.profile, [mapping.field]: value });
      }
    },

    concatStringToProfile(subMappings, value) {
      const split = value.split(' ');
      const result = {};

      subMappings.forEach((mapping, index) => {
        if (subMappings.length === index + 1 && split.length > subMappings.length) {
          result[mapping.field] = split.slice(index).join(' ');
        } else {
          result[mapping.field] = (split.length <= index) ? '' : split[index];
        }
      });

      return result;
    },

    updateProfile(profile) {
      this.$emit('updateProfile', profile);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-line {
  border-bottom: 1px solid #BFCBD9;
}
</style>
