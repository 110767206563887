<template lang="pug">
modal-editor.edit-action-modal(:visible="visible"
                              isRecordValid
                              title="Download Report"
                              :useDefaultFooter="false"
                              :afterClose="onClose"
                              ref="modalEditor"
                              :skipConfirmation="!isRecordDirty"
                              )
    template.form(v-slot:content, v-loading="isLoading")
      .card-title-container
        .field-container
          label Name:
          aiq-input.input-with-select(placeholder="File Name"
                                      name="name"
                                      disabled
                                      :model-value="$t(`reporting_tab.table.${compliance.name}.title`)")
            template(v-slot:append)
              aiq-select(placeholder="Extensions"
                         class="extension-select"
                         style="width: 115px"
                         v-model="selectedFileExtension")
                aiq-option(v-for="option in allowedFileExtensions"
                          :key="option"
                          :label="option"
                          :value="option")
        aiq-row.spacing.d-block
          aiq-col.field-container(:sm="12",:xs="24")
            .field-container.de-time-range-filter(v-if="compliance.useTimeRange")
              label Date Range:
              aiq-date-picker.date-range(v-model="selectedDateRange"
                            @change="handleDatePickerChange($event)"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            type="daterange"
                            placeholder="Select date range"
                            popper-class="date-picker-popup"
                            :default-time="defaultTime"
                            :shortcuts="datePickerOptions.shortcuts"
                            :disabled-date="datePickerOptions.disabledDate"
                            format="YYYY/MM/DD"
                            :clearable="true")
          aiq-col.field-container(:sm="12",:xs="24" 
                                  v-for="{ label, paramkey, valuekey, options, multiple, initialValue } in compliance.dependecies"
                                  :key="paramkey")
            .field-container.filter-in-header
              label {{ label }}:
              aiq-select(:placeholder="`Select ${label}`"
                          :multiple="multiple"
                          :clearable="multiple"
                          :value="initialValue"
                          v-model="customizedDependencies[paramkey]"
                          value-key="id"
                          size="small"
                          collapse-tags
                          :reserve-keyword="false")
                aiq-option(v-for="item in options", :key="item.id", :label="item.name", :value="valuekey ? item[valuekey] : item")

        .field-container.is-required
          label.title Select the information to include in the report:
          aiq-checkbox.select-all.check-box(:indeterminate="isIndeterminate" 
                                            v-model="selectAll" 
                                            @change="handleSelectAllChange") Select All
          aiq-checkbox-group.columns-checkboxes(v-model="selectedColumns" 
                                                @change="handleSelectedColumnsChange")
            aiq-checkbox.check-box(v-for="col in allowedColumns"
                                   :label="col" 
                                   :key="col") {{returnCheckBoxTranslatedLabel(col)}}
      .el-dialog__footer
        .footer-container
          p.required-notice * is required
          aiq-button(@click="onClose") Cancel
          aiq-button(:disabled="!canCommitAction"
                    type="primary"
                    @click="commitAction") Download
</template>

<script>

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import Editor from '@/components/Editor/Editor.vue';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import { ALLOWED_FILE_EXTENSIONS, DASHBOARD_FILE_ROUTE_PATH } from '@/constants';
import { getNonReactiveDateRange, setToEndOfDay } from '@/pages/Metrics/configs/dateRange';

const MAX_DEFAULT_DAYS = 90;

const DEFAULT_DATE_RANGE = [
  moment()
    .subtract(1, 'week')
    .startOf('day'),
  moment()
    .subtract(1, 'day')
    .endOf('day'),
];

const DATE_RANGE_OPTIONS = [
  {
    label: 'Last Week',
    startTime: () => DEFAULT_DATE_RANGE[0],
    endTime: () => DEFAULT_DATE_RANGE[1],
  },
  {
    label: 'Last Month',
    startTime: () => moment()
      .subtract(1, 'month')
      .startOf('day'),
    endTime: () => DEFAULT_DATE_RANGE[1],
  },
  {
    label: 'Last 60 Days',
    startTime: () => moment()
      .subtract(60, 'days') // eslint-disable-line no-magic-numbers
      .startOf('day'),
    endTime: () => DEFAULT_DATE_RANGE[1],
  },
  {
    label: 'Last 90 Days',
    startTime: () => moment()
      .subtract(MAX_DEFAULT_DAYS, 'days')
      .startOf('day'),
    endTime: () => DEFAULT_DATE_RANGE[1],
  },
];

const createPickerDateRangeOption = option => ({
  text: option.label,
  value() {
    const range = [option.startTime(), option.endTime()];
    return range;
  },
});

@Component({
  name: 'export',
  extends: Editor,
  components: {},
  props: {
    compliance: {
      type: Object,
      default: () => ({ columns: [] }),
    },
  },
  emits: ['commitAction', 'close'],
  computed: {
    allowedFileExtensions() {
      return Object.values(this.FILE_EXTENSIONS);
    },
    allowedColumns() {
      return this.compliance.columns;
    },
    canCommitAction() {
      return this.selectedColumns.length;
    },
    datePickerOptions() {
      return {
        shortcuts: DATE_RANGE_OPTIONS.map(createPickerDateRangeOption),
        disabledDate: (time) => time.getTime() > moment().endOf('day'),
      };
    },
    defaultTime() {
      // Docs: https://element-plus.org/en-US/component/datetime-picker.html#default-time-value-for-start-date-and-end-date
      // Per element plus docs, default time property do not care about date information it only use time from the date ranges defined bellow
      const currentDate = new Date();
      return [new Date(currentDate.getFullYear(), 1, 1, 0, 0, 0), new Date(currentDate.getFullYear(), 2, 1, 23, 59, 59)]; // eslint-disable-line no-magic-numbers
    },
  },
})
class ExportPanel extends Vue {
  FILE_EXTENSIONS = ALLOWED_FILE_EXTENSIONS;
  selectedColumns = [];
  selectedDateRange = cloneDeep(DEFAULT_DATE_RANGE);
  selectedFileExtension = ALLOWED_FILE_EXTENSIONS.CSV;
  customizedDependencies = {};
  selectAll = true;
  isLoading = false;
  isCustomDateRange = false;
  // Property to achieve a 'check all' effect. Same as indeterminate in native checkbox.
  isIndeterminate = false;
  mounted() {
    this.selectedColumns = [...this.allowedColumns];

    if (!isEmpty(this.compliance.dependecies)) {
      this.customizedDependencies = this.compliance.dependecies
        .filter(d => d.initialValue)
        .reduce((acc, current) => ({ ...acc, [current.paramkey]: current.initialValue }), {});
    }
  }
  handleSelectAllChange(val) {
    this.selectedColumns = val ? this.allowedColumns : [];
    this.isIndeterminate = false;
  }
  handleSelectedColumnsChange(value) {
    const checkedCount = value.length;
    this.selectAll = checkedCount === this.allowedColumns.length;
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.allowedColumns.length;
  }
  resolveRecord() {
    return Promise.resolve(this.record);
  }
  returnCheckBoxTranslatedLabel(key){
    const translationKey = key.toLowerCase().replaceAll(' ', '_');
    // return translated label if key is available, if not return key as default value
    return this.$t(`reporting_tab.${translationKey}`, key);
  }
  async commitAction() {
    if (this.isCustomDateRange) {
      if (!(await this.customDateRangeConfirm())) return;
    }
    this.isLoading = true;
    const params = {
      name: this.compliance.name,
      columns: this.selectedColumns,
      headers: this.selectedColumns.map(item=> this.returnCheckBoxTranslatedLabel(item)),
      fileExtension: this.selectedFileExtension,
      fileUrlPrefix: DASHBOARD_FILE_ROUTE_PATH,
      ...this.customizedDependencies,
    };
    if (get(this.selectedDateRange, 'length')) {
      Object.assign(params, {
        startDate: moment(this.selectedDateRange[0]).unix(),
        endDate: moment(this.selectedDateRange[1]).unix(),
      });
    }
    this.$emit('commitAction', params);
  }
  customDateRangeConfirm() {
    const title = 'Warning';
    const text = `Selecting a large date range may take excessive time to download. 
    <br/><br/> Do you want to continue? `;

    return new Promise(resolve => {
      this.$aiq.confirm(title, text, {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
      })
        .then(() => resolve(true), () => resolve(false));
    });
  }

  handleDatePickerChange(newDateRange) {
    this.selectedDateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
  }

  @Watch('opened')
  openedWatcher(newValue) {
    if (!newValue) {
      this.isLoading = false;
    }
  }
  @Watch('selectedDateRange')
  selectedDateRangeWatcher(newValue) {
    if (!get(newValue, 'length')) {
      this.isCustomDateRange = true;
      return;
    }
    const startDate = moment(newValue[0]);
    const endDate = moment(newValue[1]);
    const diffInMonths = endDate.diff(startDate, 'days');
    this.isCustomDateRange = diffInMonths >= MAX_DEFAULT_DAYS;
  }
  onClose() {
    this.$emit('close');
  }
}
export default toNative(ExportPanel);
</script>


<style lang="scss" scoped>
@import "./../../../styles/media-queries.scss";

.edit-action-modal {
  color: #475669;
  font-family: museo sans, Roboto, sans-serif;
  label {
    color: #475669;
    font-size: 14px;
    font-weight: 100;
  }
}

.columns-checkboxes {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  .el-checkbox{
    width: 30.5%;
  }
  @include mobile{
    .el-checkbox{
      width: 100%;
    }
  }
  .el-checkbox:first-child {
    margin-left: 15px;
  }
}
.select-all{
  display: block;
  margin: 10px 0 5px 0;
}

.required-notice {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 8px;
  color: red;
  font-size: 14px;
  font-weight: 100;
}

.is-required {
  .title {
    font-size: 12px !important;
  }
  label::before {
    margin-right: 5px;
    content: "*";
    color: red;
  }
  label.el-checkbox {
    &::before {
      content: "" !important;
    }
  }
}
.de-time-range-filter {
  .date-range {
    margin-top: 10px;
    font-size: 12px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.spacing > .field-container:nth-child(odd) {
  padding-right: 5px; 
}
.spacing > .field-container:nth-child(even) {
  padding-left: 5px;
}

.spacing > .field-container:nth-child(n+3) {
  padding-top: 14px;
}

.input-with-select {
  margin-top: 10px;
  margin-bottom: 14px;
}

:deep(.input-with-select > .el-input__inner) {
  pointer-events:none;
  color:#AAA;
  background:#F5F5F5;
}
:deep(.extension-select.el-select .el-input) {
  width: 110px;
  max-height: 30px;
}
:deep(.el-input-group__append){
  border:1px;
}
:deep(.modal-editor.el-dialog .el-dialog__body .el-select){
  margin-top: 0px;
  margin-bottom: 0px;
}
:deep(.modal-editor.el-dialog .el-dialog__body .field-container){
  flex: unset;
}
:deep(.el-select-dropdown) {
  top:-18px;
}
.check-box {
  height: 100%;
  margin-right: 0px;
}
</style>
